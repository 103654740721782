.product {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;
}

.product_desktop {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.product_topnav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  box-shadow: 0px 1px 6px 0px #0000001f;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.product_topnav_desktop {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-shadow: 0px 4px 6px 0px #0000001f;
  width: 80%;
  background-color: white;
  z-index: 999;
}

.product_topnavcenteralign {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.product_topnav_left {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 20px;
  padding-left: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product_topnav_right {
  width: calc(80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.product_topnav_right:hover {
  background-color: #f3f3f3;
}

.product_topnav_right:active {
  transform: scale(0.6);
}

.product_body {
  width: 100%;
}

.product_bodycenteralign {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.product_body_desktop {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.product_body_photos {
  width: 100%;
  margin-top: 14px;
}
@media only screen and (min-width: 1050px) {
  .product_body_photos {
    width: 50% !important;
  }
}

.product_body_photos_previewbox {
  width: calc(100vw - 28px);
  margin-left: 14px;
  height: calc(100vw - 28px);
}
@media only screen and (min-width: 1050px) and (max-width: 1300px) {
  .product_body_photos_previewbox {
    width: calc(37vw - 28px) !important;
    height: calc(37vw - 28px) !important;
  }
}
@media only screen and (min-width: 1301px) {
  .product_body_photos_previewbox {
    width: calc(500px - 28px) !important;
    height: calc(500px - 28px) !important;
  }
}

.product_body_photos_previewbox_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.product_body_photos_selectionScrollBar {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  padding-left: 14px;
  gap: 10px;
}

.product_body_photos_selectionScrollBar_imgbox {
  width: 60px;
  height: 60px;
  transition: transform 0.3s;
}

.product_body_photos_selectionScrollBar_imgbox_active {
  width: 60px;
  height: 60px;
  border: 2px solid green;
  border-radius: 4px;
}

.product_body_photos_selectionScrollBar_imgbox_active:active,
.product_body_photos_selectionScrollBar_imgbox:active {
  transform: scale(0.6);
}

.product_body_photos_selectionScrollBar_imgbox_img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.product_body_meta {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 18px;
  padding-bottom: 32px;
}
@media only screen and (min-width: 1050px) {
  .product_body_meta {
    width: 50% !important;
  }
}

.product_body_meta_title {
  padding-bottom: 8px;
  font-size: 14px;
  color: rgb(114, 114, 114);
}

.product_body_meta_description {
  line-height: 1.5;
}

.product_bottombar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 1px 2px 0px #0000001f;
  width: 100%;
  background-color: white;
  height: calc(60px + env(safe-area-inset-bottom));
  padding-bottom: calc(1px + env(safe-area-inset-bottom));
  box-shadow: 0px -1px 6px 0px #0000001f;
  padding-left: 14px;
  padding-right: 14px;
}

.product_bottombar_desktop {
  width: 100%;
  background-color: white;
  padding-right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
}

.product_bottombar_left {
  width: 50%;
}

.product_bottombar_left_select {
  height: 33px;
  background-color: #e2e2e2;
  width: 100%;
  border-radius: 999px;
  color: black;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1em;
  letter-spacing: -0.5px;
  cursor: pointer;
}
.product_bottombar_right {
  width: 50%;
  height: 40px;
  background-color: green;
  border-radius: 999px;
  margin-left: 14px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.product_bottombar_right:active {
  transform: scale(0.6);
}

/* FONTS */
/* inter-tight-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/inter-tight-v3-latin-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/inter-tight-v3-latin-100italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/inter-tight-v3-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/inter-tight-v3-latin-200italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/inter-tight-v3-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/inter-tight-v3-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/inter-tight-v3-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/inter-tight-v3-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter-tight-v3-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/inter-tight-v3-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/inter-tight-v3-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/inter-tight-v3-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/inter-tight-v3-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/inter-tight-v3-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/inter-tight-v3-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/inter-tight-v3-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/inter-tight-v3-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-tight-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter Tight";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/inter-tight-v3-latin-900italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Skalierungsfaktoren */

/* alles unter 692 muss auf mobile seite redirecten? */
@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .app {
    padding: 0px !important;
    gap: 0px !important;
    height: 100% !important;
  }
}

/* Mobil/Tablet */
@media only screen and (min-width: 1050px) {
}

*,
*:before,
*:after {
  margin: 0pt;
  padding: 0pt;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  touch-action: manipulation;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.activeLink {
  color: var(--lfmainColor);
}

.bold {
  font-weight: var(--boldWeight);
}

.semibold {
  font-weight: var(--semiboldWeight);
}

.medium {
  font-weight: var(--mediumWeight);
}

.content-pad {
  width: 100%;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  background: rgba(214, 214, 214, 0);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(199, 199, 199, 0);
}

:focus-visible {
  outline: 0px solid white;
}

input:focus::placeholder {
  color: lightgrey;
}

input::placeholder {
  color: black;
}

/** 
 * The default focus style is likely provided by Bootstrap or the browser
 * but here we override everything else with a visually appealing cross-
 * browser solution that works well on all focusable page elements
 * including buttons, links, inputs, textareas, and selects.
 */
*:focus {
  outline: 0 !important;
}

/**
   * Undo the above focused button styles when the element received focus
   * via mouse click or touch, but not keyboard navigation.
   */
*:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

#root {
  height: 100%;
}

:root {
  height: 100%;
  color: var(--lfsecondColor);
  /* FontFamilys - Schriftartvariablen */
  --firstFont: "Inter Tight", normal;
  --secondFont: "Inter-Medium", "Inter", sans-serif;

  /* FontSizes - Schriftgroessenvariablen */
  --xxxsFontSize: 0.6em;
  --xxsFontSize: 0.7em;
  --xsFontSize: 0.8em;
  --smallFontSize: 0.9em;
  --regularFontSize: 1em;
  --mediumFontSize: 1.1em;
  --LargeFontSize: 1.2em;
  --XLFontSize: 1.3em;
  --XXLFontSize: 1.4em;
  --XXXLFontSize: 1.5em;
  --XXXXLFontSize: 1.6em;

  /* FontWeights - Schriftdicke */
  --smallWeight: 300;
  --regularWeight: 400;
  --mediumWeight: 500;
  --semiboldWeight: 600;
  --boldWeight: 700;
  --extraBoldWeight: 800;

  /* letterSpacings - Schriftabstand */
  --widerLetterSpacing: 1.2px;
  --halfWiderLetterSpacing: 0.6px;
  --noLetterSpacing: 0px;
  --halfTighterLetterSpacing: -0.6px;
  --tighterLetterSpacing: -1.2px;

  /* Box Shadows */
  --basicBoxShadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  --highlightBoxShadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);

  /* Border Radius */
  --xsBorderRadius: 8px;
  --smallBorderRadius: 8px;
  --basicBorderRadius: 12px;
  --roundBorderRadius: 999px;

  /* colors - Farbvariablen */
  --white: #ffffff;
  --black: #000000;
  --darkGrey: #222222;
  --lightGrey: #f2f1f6;
  --leefGreen: #00ad4b;
  --greenGradient: linear-gradient(267deg, #00ff6f 0%, #007a35 100%);
  --redGradient: linear-gradient(267deg, #ff0000 0%, #7a0000 100%);
  --darkWhite: #dbdbdb;
  --accentBlue: #1c74e4;
  --accentGreen: #239f33;
  --greyBoxOnGrey: #e4e6eb;
  --greyBoxOnWhite: #f5f5f5;
  --hidingGrey: #b5b5b5;
  --exitGrey: #575757;
  --exitBorderBottomGrey: #dddddd;
  --exitInfoGrey: #a3a3a3;
  --red: #cf0606;

  --lfmainColor: #f58700;
  --lfsecondColor: #222222;
  --lfthirdColor: #707070;
  --primaryBackground: #ffffff;
  --secondaryBackground: #f2f2f2;
  --buttonOnWhite: #f0f1f5;

  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(
    safe-area-inset-bottom
  ); /* THIS ONE GETS US THE HOME BAR HEIGHT: getComputedStyle(document.documentElement).getPropertyValue("--sab")*/
  --sal: env(safe-area-inset-left);
}

/*
FÜR DESKTOP VERSION:
HTML CODE ENTFERNEN
BOTTOMNAV MAX WIDTH ENTFERNEN
CHECKOUT BOTTOMBAR MAX WIDTH ENTFERNEN
collabodetail_chat_bottombar max width entfernen

*/

html {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  background-color: var(--primaryBackground);
  width: 100%;

  overflow-y: scroll;
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  white-space: pre\9;
}

.app {
  display: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

div {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.d-none {
  display: none !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  border-radius: 12px;
  background-color: #fefefe;
  margin: 50% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80vw; /* Could be more or less, depending on screen size */
}

.modal-content_option {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--MediumFontSize);
}

.modal-content_option_bottomLine {
  border-bottom: 1px solid lightgray;
}

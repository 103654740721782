.cart {
  padding-top: 60px;
  padding-bottom: 120px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.cart_topnav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  box-shadow: 0px 1px 6px 0px #0000001f;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.cart_topnav_desktop {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.cart_topnav_left {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 20px;
  padding-left: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cart_topnav_right {
  width: calc(80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.cart_topnav_right:active {
  transform: scale(0.6);
}

.cart_body {
  width: 100%;
}

.cart_body_cartlist {
  width: 100%;
}

.cart_body_cartlist_product {
  width: 100%;
  padding: 14px;
}

.cart_body_cartlist_product_top {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cart_body_cartlist_product_top_left {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cart_body_cartlist_product_top_left_img {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  object-fit: cover;
}

.cart_body_cartlist_product_top_middle {
  width: calc(100% - 90px - 20px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  text-decoration: underline;
}

.cart_body_cartlist_product_top_right {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.cart_body_cartlist_product_bottom {
  height: 40px;
}

.cart_body_cartlist_product_bottom_left {
}

.cart_body_cartlist_product_bottom_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.cart_body_cartlist_product_bottom_right_count {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cart_body_cartlist_product_bottom_right_count_leftbtn {
  width: 40px;
  height: 40px;
  background-color: #e2e2e2;
  color: black;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding-bottom: 2px;
  transition: transform 0.3s;
  cursor: pointer;
}

.cart_body_cartlist_product_bottom_right_count_num {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  color: black;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cart_body_cartlist_product_bottom_right_count_rightbn {
  width: 40px;
  height: 40px;
  background-color: #e2e2e2;
  color: black;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding-bottom: 3px;
  transition: transform 0.3s;
  cursor: pointer;
}

.cart_body_cartlist_product_bottom_right_count_rightbn:active,
.cart_body_cartlist_product_bottom_right_count_leftbtn:active {
  transform: scale(0.6);
}

.cart_bottombar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 1px 2px 0px #0000001f;
  width: 100%;
  background-color: white;
  height: calc(100px + env(safe-area-inset-bottom));
  padding-bottom: calc(1px + env(safe-area-inset-bottom));
  box-shadow: 0px -1px 6px 0px #0000001f;
  padding-left: 14px;
  padding-right: 14px;
}
@media only screen and (min-width: 1050px) {
  .cart_bottombar {
    width: 20% !important;
  }
}

.cart_bottombar_sum {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-bottom: 12px;
}

.cart_bottombar_sum_left {
}

.cart_bottombar_btn {
  width: 100%;
  height: 40px;
  background-color: green;
  border-radius: 999px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.cart_bottombar_btn:active {
  transform: scale(0.6);
}

.checkout {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;
}

.checkout_desktop {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkout_topnav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  box-shadow: 0px 1px 6px 0px #0000001f;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.checkout_topnav_desktop {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-shadow: 0px 4px 6px 0px #0000001f;
  width: 80%;
  background-color: white;
  z-index: 999;
}

.checkout_topnavcenteralign {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.checkout_topnav_left {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 20px;
  padding-left: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.checkout_topnav_right {
  width: calc(80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.checkout_topnav_right:hover {
  background-color: #f3f3f3;
}

.checkout_topnav_right:active {
  transform: scale(0.6);
}

.checkout_body {
  width: 100%;
}

.checkout_body_desktop {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.checkout_bodycenteralign {
  width: 100%;
  max-width: 1000px;
}

.checkout_section {
  margin-top: 12px;
}

.checkout_body_title {
  padding-left: 14px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.checkout_body_fullrow {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.checkout_body_fullrow_title {
  padding-bottom: 8px;
}

.checkout_body_fullrow_input {
  width: 100%;
  height: 100%;
  border: 1px solid #222222;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 1em;
  height: 40px;
}

.checkout_body_fullrow_divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.checkout_body_fullrow_half {
  width: 50%;
}

.checkout_body_fullrow_half_title {
  padding-bottom: 8px;
}

.checkout_body_fullrow_half_input {
  width: 100%;
  height: 40px;
  border: 1px solid #222222;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 1em;
}

.checkout_body_CTA {
  width: calc(100% - 28px);
  margin-left: 14px;
  height: 50px;
  background-color: green;
  border-radius: 999px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.checkout_body_CTA_loading {
  width: calc(100% - 28px);
  margin-left: 14px;
  height: 50px;
  background-color: white;
  border: 1px solid green;
  border-radius: 999px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  cursor: not-allowed;
}

.checkout_body_CTA:active,
.checkout_body_CTA_loading:active {
  transform: scale(0.6);
}

.home {
  padding-top: 60px;
  padding-bottom: 180px;
  width: 100%;
}

.home_desktop {
  padding-top: 60px;
  padding-bottom: 180px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.home_sidebar {
  width: 20%;
}

.home_sidebar_sticky {
  position: fixed;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  box-shadow: -4px 0px 6px 0px #0000001f;
}

.home_topnav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  box-shadow: 0px 1px 6px 0px #0000001f;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.home_topnav_desktop {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-shadow: 0px 4px 6px 0px #0000001f;
  width: 80%;
  background-color: white;
  z-index: 999;
}

.home_topnavcenteralign {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.home_topnav_searchbar {
  width: calc(100% - 24px);
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #222222;
  border-radius: 8px;
}

.home_topnav_searchbar_input {
  width: calc(100% - 30px);
  height: 40px;
  border: 0px solid #ffffff;
  border-radius: 8px;

  padding-left: 10px;
  padding-right: 10px;

  font-size: 1em;
}

.home_topnav_searchbar_close {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.home_topnav_searchbar_close:active {
  transform: scale(0.6);
}

.home_topnav_searchbox {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home_topnav_searchbox_btn {
  width: 33px;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
  border-radius: 9999px;
}

.home_topnav_scrollbar {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  height: 100%;
  padding-right: 14px;
}

.home_topnav_scrollbar_filteroption {
  height: 33px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

.home_topnav_scrollbar_filteroption_active {
  height: 33px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.home_body {
  width: 100%;
}

.home_body_desktop {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_bodycenteralign {
  width: 100%;
  max-width: 1000px;
}

.home_body_banner {
  width: 100%;
  height: 200px;
}

.home_body_banner_desktop {
  width: 100%;
  height: 400px;
}

.home_body_banner_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.home_body_banner_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.141);
  color: white;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -404px;
  z-index: 9;
  position: relative;
  padding-bottom: 20px;
}

.home_body_banner_overlay_logo {
  width: 100px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 3px;
}

.home_body_products {
  width: 100%;
}

.home_body_products_sectionTitle {
  font-weight: 600;
  font-size: 24px;
  margin-left: 8px;
  margin-top: 18px;
}

/** Product Mobile **/
@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .home_body_products_product {
    width: calc(100% - 12px);
    margin-left: 6px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    height: 122px;
    cursor: pointer;
  }

  .home_body_products_product:hover {
    background-color: #f7f7f7;
  }

  .home_body_products_product_left {
    height: 120px;
  }

  .home_body_products_product_left_img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
  }

  .home_body_products_product_right {
    width: calc(100% - 120px);
    height: 100%;
  }

  .home_body_products_product_right_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
    height: calc(120px - 53px);
    padding-top: 25px;
  }

  .home_body_products_product_right_top_left {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 10px;
  }

  .home_body_products_product_right_top_right {
    padding-right: 10px;
  }

  .home_body_products_product_right_bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 53px;
  }

  .home_body_products_product_right_bottom_space {
    width: calc(100% - 110px);
    height: 100%;
  }

  .home_body_products_product_right_bottom_hitbox_addbtn {
    width: 100px;
    background-color: green;
    color: white;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 33px;
    border-radius: 8px;
    padding-bottom: 7px;
    margin-right: 10px;
    transition: transform 0.3s;
  }

  .home_body_products_product_right_bottom_hitbox:focus-within
    .home_body_products_product_right_bottom_hitbox_addbtn,
  .home_body_products_product_right_bottom_hitbox:active
    .home_body_products_product_right_bottom_hitbox_addbtn {
    transform: scale(0.6);
  }

  .home_body_products_product_right_bottom_hitbox_space {
    height: 20px;
    width: 100px;
  }
}
/** Product Desktop **/
@media only screen and (min-width: 1050px) {
  .home_body_products_product {
    width: calc(100% - 12px);
    height: 202px;
    margin-left: 6px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    cursor: pointer;
  }

  .home_body_products_product:hover {
    background-color: #f7f7f7;
  }

  .home_body_products_product_left {
    height: 200px;
  }

  .home_body_products_product_left_img {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
  }

  .home_body_products_product_right {
    width: calc(100% - 200px);
    height: 100%;
  }

  .home_body_products_product_right_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
    height: calc(120px - 53px);
    padding-top: 25px;
  }

  .home_body_products_product_right_top_left {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 10px;
  }

  .home_body_products_product_right_top_right {
    padding-right: 10px;
  }

  .home_body_products_product_right_bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 133px;
  }

  .home_body_products_product_right_bottom_space {
    width: calc(100% - 110px);
    height: 100%;
  }

  .home_body_products_product_right_bottom_hitbox_addbtn {
    width: 120px;
    background-color: green;
    color: white;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 43px;
    border-radius: 8px;
    padding-bottom: 7px;
    margin-right: 10px;
    transition: transform 0.3s;
  }

  .home_body_products_product_right_bottom_hitbox:focus-within
    .home_body_products_product_right_bottom_hitbox_addbtn,
  .home_body_products_product_right_bottom_hitbox:active
    .home_body_products_product_right_bottom_hitbox_addbtn {
    transform: scale(0.6);
  }

  .home_body_products_product_right_bottom_hitbox_space {
    height: 20px;
    width: 120px;
  }
}

.home_bottombar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 1px 2px 0px #0000001f;
  width: 100%;
  background-color: white;
  height: calc(60px + env(safe-area-inset-bottom));
  padding-bottom: calc(1px + env(safe-area-inset-bottom));
}

.home_bottombar_button {
  width: calc(100% - 28px);
  height: 40px;
  background-color: green;
  border-radius: 999px;
  margin-left: 14px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.home_bottombar_button:active {
  transform: scale(0.6);
}

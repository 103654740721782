.guests {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  gap: 10px;
}

.guests_element {
  border: 1px solid #dddddd;
  border-radius: 16px;
  width: calc(100% - 20px);
  margin-left: 10px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-top: 10px;
}

.guests_element_personrow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  gap: 10px;
}

.guests_element_personrow_imgbox {
  width: 60px;
  height: 60px;
}

.guests_element_personrow_imgbox_img {
  width: 100%;
  height: 100%;
  border-radius: 99999px;
}

.guests_element_personrow_name {
  width: calc(100% - 60px);
  font-weight: 600;
}

.guests_element_foodrow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
}

.guests_element_foodrow_element {
  width: 100%;
}
